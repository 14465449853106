import styled from "@emotion/styled";
import mainBg from "../../assets/bg.jpg";

export const MainWrapper = styled("div")`
  label: MainWrapper;
  width: 75%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #020230),
    url(${mainBg}) center;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const H1 = styled("h1")`
  width: fit-content;
  padding: 10px;
  color: #020230;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 1rem;
`;

export const Top = styled("div")`
  label: Top;
  height: 50%;
  position: relative;
`;
export const Timerr = styled("div")`
  label: Timerr;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  position: absolute;
  bottom: 10px;
  left: 80px;
`;

export const Bottom = styled("div")`
  label: Bottom;
  height: 50%;
`;
