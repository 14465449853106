import React, { useState } from "react";
import Main from "./Main/Main";
import Pyramid from "./Pyramid/Pyramid";
import Start from "./Start/Start";
import { Wrapper } from "./style";

export default function Home() {
  const [questionNumber, setQuestionNumber] = useState(1);
  const [userName, setUsername] = useState(null);
  return (
    <>
      <Wrapper>
        {userName ? (
          <>
            <Main
              setQuestionNumber={setQuestionNumber}
              questionNumber={questionNumber}
            />
            <Pyramid questionNumber={questionNumber} />
          </>
        ) : (
          <Start setUsername={setUsername} />
        )}
      </Wrapper>
    </>
  );
}
