import React, { useState } from "react";
import Timer from "../Timer/Timer";
import Trivia from "../Trivia/Trivia";
import { data } from "./Data";
import { Bottom, H1, MainWrapper, Timerr, Top } from "./style";
export default function Main({ setQuestionNumber, questionNumber }) {
  const [stop, setStop] = useState(false);
  const [earned, setEarned] = useState("$ 0");

  return (
    <>
      <MainWrapper>
        {stop ? (
          <H1>You Earned: {earned}</H1>
        ) : (
          <>
            <Top>
              <Timerr>
                <Timer setStop={setStop} questionNumber={questionNumber} />
              </Timerr>
            </Top>
            <Bottom>
              <Trivia
                data={data}
                setStop={setStop}
                setQuestionNumber={setQuestionNumber}
                questionNumber={questionNumber}
                setEarned={setEarned}
              />
            </Bottom>
          </>
        )}
      </MainWrapper>
    </>
  );
}
