import React from "react";
import { StartButton, StartInput, StartWrapper } from "./style";

export default function Start({ setUsername }) {
  const inputRef = React.useRef();
  const clickHandler = () => {
    inputRef.current.value && setUsername(inputRef);
  };
  return (
    <>
      <StartWrapper>
        <StartInput placeholder="Enter Your Name" ref={inputRef} />
        <StartButton onClick={clickHandler}>Start</StartButton>
      </StartWrapper>
    </>
  );
}
