import React, { useEffect, useState } from "react";
import useSound from "use-sound";
import correctSound from "../../assets/sounds/correct.mp3";
import play from "../../assets/sounds/play.mp3";
import wrongSound from "../../assets/sounds/wrong.mp3";
import { moneyPyramid } from "../Pyramid/Data";
import { Answer, Answers, Question, TriviaWrapper } from "./style";

export default function Trivia({
  data,
  setStop,
  setQuestionNumber,
  questionNumber,
  setEarned,
}) {
  const [question, setQuestion] = useState(null);
  const [selectAnswer, setSelectAnswer] = useState(null);
  const [activeAnimation, setActiveAnimation] = useState(0);
  const earnedMoney = [...moneyPyramid].reverse();
  const [lestPlay] = useSound(play);
  const [wrongAnswer] = useSound(wrongSound);
  const [correctAnswer] = useSound(correctSound);

  useEffect(() => {
    lestPlay();
  }, [lestPlay]);
  useEffect(() => {
    setQuestion(data[questionNumber - 1]);
  }, [data, questionNumber]);

  const handleClick = (answer) => {
    if (activeAnimation === 0) {
      setActiveAnimation(answer.id);
      setTimeout(() => {
        setSelectAnswer(answer);
        setTimeout(() => {
          if (answer.correct) {
            correctAnswer();
            setTimeout(() => {
              setQuestionNumber((prev) => prev + 1);
              setActiveAnimation(0);
              setSelectAnswer(null);
              setEarned(earnedMoney[questionNumber - 1].amount);
            }, 1000);
          } else {
            wrongAnswer();
            setTimeout(() => {
              setQuestionNumber(1);
              setActiveAnimation(0);
              setSelectAnswer(null);
              setStop(true);
            }, 1000);
          }
        }, 3000);
      }, 3000);
    }
  };

  return (
    <>
      <TriviaWrapper>
        <Question>{question?.question}</Question>
        <Answers>
          {question?.answers.map((item) => (
            <Answer
              active={activeAnimation === item.id}
              correct={selectAnswer?.id === item.id ? selectAnswer.correct : ""}
              wrong={selectAnswer?.id === item.id ? !selectAnswer.correct : ""}
              onClick={() => {
                handleClick(item);
              }}
              key={item.id}
            >
              {item.text}
            </Answer>
          ))}
        </Answers>
      </TriviaWrapper>
    </>
  );
}
