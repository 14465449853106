export const data = [
  {
    id: 1,
    question: "Rolex is a company that specializes in what type of product?",
    answers: [
      {
        id: 1,
        text: "Phone",
        correct: false,
      },
      {
        id: 2,
        text: "Watches",
        correct: true,
      },
      {
        id: 3,
        text: "Food",
        correct: false,
      },
      {
        id: 4,
        text: "Cosmetic",
        correct: false,
      },
    ],
  },
  {
    id: 2,
    question: "When did the website `Facebook` launch?",
    answers: [
      {
        id: 1,
        text: "2004",
        correct: true,
      },
      {
        id: 2,
        text: "2005",
        correct: false,
      },
      {
        id: 3,
        text: "2006",
        correct: false,
      },
      {
        id: 4,
        text: "2007",
        correct: false,
      },
    ],
  },
  {
    id: 3,
    question: "Who played the character of harry potter in movie?",
    answers: [
      {
        id: 1,
        text: "Johnny Deep",
        correct: false,
      },
      {
        id: 2,
        text: "Leonardo Di Caprio",
        correct: false,
      },
      {
        id: 3,
        text: "Denzel Washington",
        correct: false,
      },
      {
        id: 4,
        text: "Daniel Red Cliff",
        correct: true,
      },
    ],
  },
];
